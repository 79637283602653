<script setup>
import { useLayoutStore } from '~/stores/layout.store';
import { useUserStore } from '~/stores/user.store';
const userStore = useUserStore();
const user = computed(() => userStore.userGetter());



const layoutStore = useLayoutStore();
const menus = ref([])
menus.value = await layoutStore.fetchMenusInFooter('website');

        


</script>
<template>
    <div v-for="(menu, i) in menus" :key="i">
        <template v-if="!menu.only_for_logged_in_users||user!=undefined">
            <h4 class="text-white text-2xl mb-5">{{ menu.name }}</h4>
            <ul v-if="menu.children.length">   
                <li class="truncate" v-for="(child, i) in menu.children"><NuxtLink :to="child.url"
                        class="text-zinc-300 text-base mb-3 transition-all duration-300 hover:text-zinc-100 hover:ps-2">{{child.name}}</NuxtLink></li>
            </ul>
        </template>
        
    </div>
</template>